@font-face {
  font-family: Poppins;
  src: url(../public/fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: PoppinsThick;
  src: url(../public/fonts/Poppins-SemiBold.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: Poppins;
  vertical-align:middle;
  
}

.thick
{
  font-family: PoppinsThick;
}

.legalStartText
{
  font-family: PoppinsThick;
}

html, body
{
  width:100%;
  min-height:100%;
  display:flex;
}

#root
{
  width:100%;
  min-height:100%;
  display:flex;
  flex-direction: column;
}

.grContact{
  width:50px;
  height:50px;
  margin-left: 10px;
}
.grContact path
{
  
    stroke:royalblue;
   

}


.button
{
  padding-left:4rem !important;
  padding-right:4rem !important;
  padding-top:1rem !important;
  padding-bottom:1rem !important;
}

@media (max-width: 550px) 
{ 
  .button
  {
      
       
    padding-left:1rem !important;
    padding-right:1rem !important;
    padding-top:1rem !important;
    padding-bottom:1rem !important;
    margin-left:2rem !important;
    margin-right:2rem !important;
        
  } 

  .getInTouch
  {
    margin-left:4rem;
    margin-right:4rem
  }

  .paper
  {
    padding:0;
  }

  .button2
  {
    width:auto !important;

  }
}

@media (max-width: 1200px) 
{ 
  .reversed
  {
    flex-direction: column-reverse !important;
    width:100%;
  }

  .needsColumn
  {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
  }

  .whatIsHousingDisrepair
  {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0rem;
    padding-right: 0rem;
  } 
  
  .makeBigger
  {
    width:100% !important;
    text-align: center;
  }
  .center
  {
    text-align: center;
  }
  .legalWrapper
  {
    width:100% !important;
    padding-left:0 !important;
    padding-right: 0 !important;
  }
  .legalWrapperInner
  {
    width:100% !important;
    padding-left:1.75rem !important;
    padding-right: 1.75rem !important;
  }
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.05);
  }
  100% {
      transform: scale(1);
  }
}
